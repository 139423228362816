import { ApiFactory } from './Api';

const api = new ApiFactory();
const apiCustomerUrl = process.env.REACT_APP_API_CUSTOMER_URL;

async function login(request) {
    const response = await api.post(`/login`, request, apiCustomerUrl);
    return response.data;
}

async function logout() {
    const response = await api.post(`/logout`, undefined, apiCustomerUrl);
    return response.data;
}

export { login, logout };
